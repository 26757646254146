.service-page .services-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  padding: 2rem;
  padding-top: 0.5rem;
}

.service-page-title{
  margin-top: 140px;
  text-align: center;
  font-size: 30px;
}

.service-page .service-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  text-align: center;
  width: 230px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  height: 350px;
  position: relative;
}

.service-page .service-item img {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 10px;
}

.service-page .service-item h3 {
  font-size: 1.2rem;
  color: #333;
  margin-block-end: 10px;
}

.service-page .service-item p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
  margin-block-start: 0;
  margin-block-end: 10px;
}

.service-address {
  font-size: 0.85rem;
  color: #70757a;
  margin-bottom: 10px;
}

.service-page .service-item button {
  padding: 0.5rem 1rem;
  background-color: #0fbaf4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  align-self: center;
  margin-top: auto; /* Push button to bottom */
}

.favorite-icon {
  cursor: pointer;
  font-size: 1.2rem;
  color: black;
  transition: transform 0.3s;
}

.favorite-icon:hover {
  transform: scale(1.2);
}

.service-page .service-item button:hover {
  background-color: #0056b3;
}

.service-page .service-item:hover {
  transform: translateY(-5px);
}

.service-actions {
  position: absolute;
  top: 1px;
  right: 5px;
  z-index: 1;
}

/* Discount Label Styling */
.discount-badge {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FF4E50;
  color: white;
  padding: 0.3rem 0.6rem;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 0 0 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Animation for the discount badge */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.discount-badge {
  animation: pulse 2s infinite;
}
