.select-continent-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to top, #2eb5e1, #eaf4fc);
  font-family: 'JETSansDigital', sans-serif;
}

.select-continent-container {
  background-color: #ffffff;
  padding: 2rem 3rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.select-continent-header {
  font-size: 1.75rem;
  color: #454545;
  margin-bottom: 1.5rem;
}

label {
  display: block;
  font-size: 1.25rem;
  color: #555;
  margin-bottom: 1rem;
}

select {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 1.5rem;
  transition: border-color 0.3s ease;
}

select:focus {
  border-color: #007bff;
  outline: none;
}

button {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}
