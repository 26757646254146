/* Landing Page Container */
.landing-page {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(120deg, #eaf4fc, #2eb5e1);
  color: #333;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 20px;
}

/* Header Section */
.login-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px;
}

.login-button {
  background: linear-gradient(45deg, #3bb4f2, #5ac2ff);
  color: white;
  padding: 15px 35px;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.login-button:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

.profile-icon {
  width: 40px;
  height: 40px;
  margin-top: 15px;
  margin-left: 20px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-icon:hover {
  transform: scale(1.2);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Hero Section */
.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.title-logo {
  width: 165px;
  margin-bottom: 17px;
}

.title {
  font-size: 4.5rem;
  color: #ffffff;
  background: linear-gradient(to right, #3bb4f2, #5ac2ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 0;
  margin-top: 0;
}

.motto {
  font-size: 1.8rem;
  color: #ffffff;
  margin-top: 10px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.get-started-button {
  background: linear-gradient(45deg, #3bb4f2, #5ac2ff);
  color: white;
  font-size: 1.5rem;
  padding: 15px 70px;
  border: none;
  border-radius: 50px;
  margin-top: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.get-started-button:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

/* Features Section */
.features-section {
  margin: 80px auto;
  padding: 60px 20px;
  width: 90%;
  background: linear-gradient(to top, #5ac2ff, #ffffff);
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  transform: translateY(-30px);
  animation: slideIn 1.5s ease-in-out;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.features-section h2 {
  font-size: 3rem;
  color: #3bb4f2;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.feature-card {
  background: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.feature-card h3 {
  font-size: 1.8rem;
  color: #3bb4f2;
  margin-bottom: 15px;
}

.feature-card p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

/* Testimonials Section */
.testimonials-section {
  width: 90%;
  padding: 60px 20px;
  background: linear-gradient(to bottom, #5ac2ff, #ffffff);
  border-radius: 20px;
}

.testimonials-section h2 {
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 30px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.testimonial {
  background: white;
  border-radius: 15px;
  padding: 20px;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  font-style: italic;
}

.testimonial span {
  display: block;
  margin-top: 15px;
  font-weight: bold;
  color: #3bb4f2;
}

/* Call-to-Action Section */
.cta-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
  background: none;
  color: white;
  margin-top: 30px;
  border-radius: 20px;
  width: 90%;
}

.cta-section h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.cta-button {
  background: white;
  color: #3bb4f2;
  font-size: 1.5rem;
  padding: 15px 50px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #5ac2ff;
  color: white;
  transform: scale(1.1);
}

.pricing-link {
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
  margin-top: 8px;
  text-decoration: underline;
}

.pricing-link:hover {
  color: #0056b3;
}


/* Footer */
.footer {
  text-align: center;
  padding: 20px;
  background: #333;
  color: white;
  margin-top: 0px;
  width: 100%;
}

.footer-links a {
  color: #3bb4f2;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.selection-form {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 40px;
  max-width: 800px;
  width: 87%;
  margin: 40px auto;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  animation: fadeIn 1s ease-in-out;
}

.selection-form h3 {
  grid-column: 1 / -1;
  color: #3bb4f2;
  font-size: 1.8rem;
  margin: 0 0 20px 0;
  text-align: center;
}

.selection-form .form-group {
  display: flex;
  flex-direction: column;
}

.selection-form .continent-group {
  grid-column: 1 / -1;
}

.selection-form label {
  font-weight: 600;
  margin-bottom: 8px;
  color: #555;
  font-size: 0.95rem;
  text-align: left;
}

.selection-form select {
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background-color: white;
  font-size: 1rem;
  transition: border-color 0.3s;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%233bb4f2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center;
  cursor: pointer;
}

.selection-form select:focus {
  outline: none;
  border-color: #3bb4f2;
  box-shadow: 0 0 0 2px rgba(59, 180, 242, 0.2);
}

.selection-form select:hover {
  border-color: #3bb4f2;
}

.selection-form .continent-question {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.4;
  text-align: center;
  font-weight: 500;
}

.selection-form .get-started-button {
  grid-column: 1 / -1;
  background: linear-gradient(45deg, #3bb4f2, #5ac2ff);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 15px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
  margin-top: 10px;
  box-shadow: 0 4px 15px rgba(59, 180, 242, 0.3);
}

.selection-form .get-started-button:hover {
  transform: translateY(-3px);
  filter: brightness(1.1);
  box-shadow: 0 6px 20px rgba(59, 180, 242, 0.4);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
  }

  .cta-section {
    padding: 30px 10px;
  }

  .selection-form {
    grid-template-columns: 1fr;
    padding: 30px;
  }
  
  .selection-form h3 {
    font-size: 1.5rem;
  }

}

@media (max-width: 480px) {
  .title {
    font-size: 3.5rem;
  }

  .get-started-button {
    padding: 20px 40px;
    font-size: 1.5rem;
  }

  .cta-button {
    padding: 10px 30px;
    font-size: 1rem;
  }

  .footer-links a {
    display: block;
    margin: 10px 0;
  }

  .title-logo {
    width: 165px;
    margin-bottom: 17px;
  }

  .title-container {
    margin-top: 45px;
  }

  .features-section {
    margin: 120px auto;
    padding: 10px 20px;
  }

  .features-section h2 {
    font-size: 2.2rem;
    margin-bottom: 25px;
    letter-spacing: 0.8px;
  }
  
  .features-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
  }
  
  .feature-card h3 {
    font-size: 1.7rem;
  }

  .testimonials-section {
    padding: 60px 20px;
    background: linear-gradient(to bottom, #5ac2ff, #ffffff);
    border-radius: 20px;
  }

  .selection-form {
    padding: 25px;
    margin: 30px auto;
  }
  
  .selection-form select {
    padding: 12px;
  }
  
  .selection-form .get-started-button {
    font-size: 1.1rem;
    padding: 12px;
  }
}
