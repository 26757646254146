/* CartPage.css */

.cart-page {
    padding: 20px;
    background-color: #f8f9fa;
    font-family: Arial, sans-serif;
  }
  
  .cart-page h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .cart-item span {
    font-size: 1.2rem;
    color: #555;
  }
  
  .cart-item button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s;
  }
  
  .cart-item button:hover {
    background-color: #007bff;
  }
  
  .cart-page button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cart-page button:hover {
    background-color: #007bff;
  }
  
  