.app-navigation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;
  }
  
  .app-navigation-header__back-button,
  .app-navigation-header__profile-button {
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .app-navigation-header__back-button:hover,
  .app-navigation-header__profile-button:hover {
    color: #666;
  }
  
  .app-navigation-header__back-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  
  .app-navigation-header__profile-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }