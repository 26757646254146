.order-page {
  padding: 40px 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  background-color: #f9f9f9;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
}

.order-page.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.order-page h1 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: #333;
}

.item-info {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
}

.item-info span {
  font-weight: 600;
  color: #007bff;
}

.order-methods {
  margin-top: 30px;
}

.order-methods h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #333;
}

.discount-note {
  background-color: #fff3cd;
  border-left: 4px solid #ffc107;
  padding: 15px;
  margin: 20px auto;
  max-width: 600px;
  border-radius: 4px;
  font-weight: 500;
  color: #856404;
  text-align: left;
}

.platform-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
  margin-top: 30px;
}

.platform-card {
  background-color: white;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  transition: transform 0.2s, box-shadow 0.2s;
}

.platform-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.platform-icon {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.platform-card h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #333;
}

.platform-card p {
  color: #666;
  margin-bottom: 15px;
}

.template-section {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 15px;
  margin: 10px 0 20px;
}

.template-section h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #495057;
}

.template-box {
  position: relative;
  background-color: white;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 15px;
  text-align: left;
}

.template-box p {
  margin: 0;
  padding-bottom: 35px;
  color: #495057;
  font-size: 0.9rem;
  line-height: 1.5;
}

.copy-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.copy-button:hover {
  background-color: #5a6268;
}

.contact-button {
  background-color: #007bff;
  color: white;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 6px;
  font-weight: 600;
  margin-top: auto;
  transition: background-color 0.2s;
  display: inline-block;
}

.contact-button:hover {
  background-color: #0056b3;
  text-decoration: none;
  color: white;
}

.contact-detail {
  margin-top: 12px;
  font-size: 0.9rem;
  color: #6c757d;
}

.no-platforms {
  font-size: 1.1rem;
  color: #6c757d;
  margin: 40px 0;
}

.go-back-button {
  margin-top: 40px;
  padding: 12px 25px;
  font-size: 1rem;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.go-back-button:hover {
  background-color: #5a6268;
}

.platform-icon {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (max-width: 768px) {
  .platform-grid {
    grid-template-columns: 1fr;
  }
  
  .order-page h1 {
    font-size: 1.8rem;
  }
  
  .order-methods h2 {
    font-size: 1.5rem;
  }

}