/* Login.css */

/* General container styling */
.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f4f4f4;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    padding: 20px;
  }
  
  /* Form container */
  .login-form {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    text-align: center;
  }
  
  /* Heading */
  .login-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  /* Input fields */
  .login-form input[type="email"],
  .login-form input[type="password"] {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
    color: #333;
  }
  
  /* Login button */
  .login-form button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .login-form button:hover {
    background-color: #0056b3;
  }
  
  /* Redirect link */
  .login-page p {
    margin-top: 15px;
    font-size: 14px;
  }
  
  .login-page a {
    color: #007bff;
    text-decoration: none;
  }
  
  .login-page a:hover {
    text-decoration: underline;
  }
  