/* AdminDashboard.css */

/* General Layout */
.admin-dashboard {
    background-color: #f9fafb;
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  }
  
  .main-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.5rem 1rem;
  }
  
  /* Header */
  .dashboard-header {
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 0 1rem;
  }
  
  .header-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
  }
  
  .dashboard-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1f2937;
    margin: 0;
  }
  
  .back-button {
    color: #6b7280;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.875rem;
    padding: 0.5rem;
    transition: color 0.2s;
  }
  
  .back-button:hover {
    color: #374151;
  }
  
  /* Tabs */
  .tabs-container {
    border-bottom: 1px solid #e5e7eb;
    margin-bottom: 1.5rem;
  }
  
  .tabs-nav {
    display: flex;
    margin-bottom: -1px;
  }
  
  .tab-button {
    padding: 1rem 1.5rem;
    background: none;
    border: none;
    font-size: 0.875rem;
    font-weight: 500;
    color: #6b7280;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all 0.2s;
    display: flex;
    align-items: center;
  }
  
  .tab-button:hover {
    color: #4b5563;
  }
  
  .tab-button.active {
    color: #2563eb;
    border-bottom-color: #2563eb;
  }
  
  .count-badge {
    margin-left: 0.5rem;
    background-color: #dbeafe;
    color: #1e40af;
    font-size: 0.75rem;
    padding: 0.125rem 0.5rem;
    border-radius: 9999px;
  }
  
  /* Content Cards */
  .content-card {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .card-header {
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid #f3f4f6;
  }
  
  .section-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #1f2937;
    margin: 0 0 0.25rem 0;
  }
  
  .section-subtitle {
    font-size: 0.875rem;
    color: #6b7280;
    margin: 0;
  }
  
  /* Request Lists */
  .request-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  .request-item {
    border-bottom: 1px solid #f3f4f6;
    transition: background-color 0.2s;
    padding: 1rem 1.5rem;
  }
  
  .request-item:last-child {
    border-bottom: none;
  }
  
  .request-item:hover {
    background-color: #f9fafb;
  }
  
  .request-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .request-info {
    flex: 1;
  }
  
  .request-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #1f2937;
    margin: 0 0 0.5rem 0;
  }
  
  .request-description {
    font-size: 0.875rem;
    color: #4b5563;
    margin: 0.5rem 0;
  }
  
  .request-detail {
    font-size: 0.875rem;
    color: #4b5563;
    margin: 0.25rem 0;
  }
  
  .request-submitter {
    font-size: 0.75rem;
    color: #9ca3af;
    margin: 0.25rem 0 0 0;
  }
  
  /* Buttons */
  .button-group {
    display: flex;
    gap: 0.5rem;
  }
  
  .approve-button {
    background-color: #10b981;
    color: white;
    border: none;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: background-color 0.2s;
  }
  
  .approve-button:hover {
    background-color: #059669;
  }
  
  .reject-button {
    background-color: #ef4444;
    color: white;
    border: none;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: background-color 0.2s;
  }
  
  .reject-button:hover {
    background-color: #dc2626;
  }
  
  /* Empty State */
  .empty-state {
    padding: 3rem 1.5rem;
    text-align: center;
    color: #6b7280;
    font-size: 0.875rem;
  }
  
  /* Responsive Design */
  @media (max-width: 640px) {
    .request-content {
      flex-direction: column;
    }
    
    .button-group {
      margin-top: 1rem;
      align-self: flex-start;
    }
    
    .approve-button, 
    .reject-button {
      width: 100%;
    }
  }