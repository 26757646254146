/* ServiceDetailPage.css - Modernized and improved */

.service-detail-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #333;
}

/* Header and navigation */
.service-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-button, .edit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f5f5f5;
  color: #333;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover, .edit-button:hover {
  background-color: #e5e5e5;
  transform: translateY(-1px);
}

.edit-button {
  background-color: #e6f7ff;
  color: #1890ff;
}

.edit-button:hover {
  background-color: #d6f0ff;
}

/* Banner styling */
.service-banner-container {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 24px;
  background-color: #f9f9f9;
}

.service-banner {
  width: 100%;
  height: 100%;
  object-fit:contain;
  transition: transform 0.3s ease;
}

.service-banner:hover {
  transform: scale(1.02);
}

/* Service name */
.service-name {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #222;
  text-align: left;
}

/* Items section */
.items-section {
  margin-bottom: 40px;
}

.items-section h2 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #333;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

.items-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.item-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.item-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
}

.item-image {
  width: 100%;
  height: 180px;
  object-fit: contain;
}

.item-details {
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.item-details h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.item-details p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 12px;
  flex-grow: 1;
  line-height: 1.4;
}

.item-price {
  font-size: 1.1rem;
  font-weight: 700;
  color: #2c3e50;
  align-self: flex-end;
}

.no-items-message {
  grid-column: 1 / -1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: center;
  color: #666;
}

/* Local business section */
.local-business-section {
  margin-bottom: 40px;
}

.business-actions {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 16px;
}

.visit-button, .location-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  border-radius: 6px;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
}

.visit-button {
  background-color: #3498db;
  color: white;
}

.visit-button:hover {
  background-color: #2980b9;
}

.location-button {
  background-color: #2ecc71;
  color: white;
}

.location-button:hover {
  background-color: #27ae60;
}

/* About section */
.about-section {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #eee;
}

.about-section h2 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #333;
}

.about-section p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 24px;
  max-width: 800px;
}

/* Carousel */
.service-carousel-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.service-carousel-slide {
  position: relative;
}

.service-carousel-image {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.service-carousel-button {
  position: absolute;
  bottom: 24px;
  right: 24px;
  padding: 12px 24px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 10;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.service-carousel-button:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

/* Modification form */
.modification-section {
  margin: 30px 0;
}

.request-modification-button {
  padding: 12px 24px;
  background-color: #f5f5f5;
  color: #333;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.request-modification-button:hover {
  background-color: #e5e5e5;
}

.modification-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.modal-container h2 {
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: #333;
}

.modification-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
}

.modification-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f5f5f5;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modification-option:hover {
  background-color: #e5e5e5;
}

.modification-option.selected {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.modification-option input {
  margin: 0;
}

textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  margin-bottom: 20px;
  resize: vertical;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.submit-modification-button {
  padding: 10px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-modification-button:hover {
  background-color: #27ae60;
}

.cancel-button {
  padding: 10px 20px;
  background-color: #f5f5f5;
  color: #333;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button:hover {
  background-color: #e5e5e5;
}

/* Loading and error states */
.service-detail-loading, 
.service-detail-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding: 40px;
  text-align: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.service-detail-error h2 {
  color: #e74c3c;
  font-size: 1.8rem;
  margin-bottom: 16px;
}

.rate-service-button {
  background-color: #ffd700;
  color: #333;
  padding: 10px 16px;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.2s ease;
}

.rate-service-button:hover {
  background-color: #ffc107;
}

.review-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.review-modal {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.star-rating {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.star {
  cursor: pointer;
  color: #ccc;
  margin: 0 5px;
}

.star.filled {
  color: #ffd700;
}

.review-modal textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  resize: vertical;
}

.review-actions button {
  margin: 0 5px;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background-color: #333;
  color: white;
  cursor: pointer;
}

.review-actions button:hover {
  background-color: #555;
}


.reviews-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.review-item {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.review-rating {
  color: #ffc107;
  font-size: 18px;
}

.review-text {
  font-style: italic;
  margin-top: 4px;
}



/* Responsive design */
@media (max-width: 768px) {
  .service-detail-page {
    padding: 16px;
  }
  
  .service-name {
    font-size: 1.8rem;
  }
  
  .service-banner-container {
    height: 200px;
  }
  
  .items-container {
    grid-template-columns: 1fr;
  }
  
  .carousel-image {
    height: 300px;
  }
  
  .about-section h2 {
    font-size: 1.5rem;
  }
  
  .business-actions {
    flex-direction: column;
  }
  
  .visit-button, .location-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .service-detail-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  
  .back-button, .edit-button {
    width: 100%;
    justify-content: center;
  }
  
  .service-banner-container {
    height: 150px;
  }
  
  .service-name {
    font-size: 1.5rem;
    text-align: center;
  }
  
  .carousel-image {
    height: 200px;
  }
  
  .carousel-button {
    bottom: 12px;
    right: 12px;
    padding: 8px 16px;
    font-size: 0.9rem;
  }
  
  .modification-options {
    flex-direction: column;
  }
  
  .modification-option {
    width: 100%;
  }
}