/* ProviderDashboard.css */

/* General Layout */
.provider-dashboard {
    background-color: #f9fafb;
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  }
  
  .main-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.5rem 1rem;
  }
  
  /* Header */
  .dashboard-header {
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
  
  .header-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
  }
  
  .header-left {
    display: flex;
    align-items: center;
  }
  
  .back-button-icon {
    font-size: 1.125rem;
    margin-right: 1rem;
    color: #6b7280;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .back-button-icon:hover {
    color: #111827;
  }
  
  .dashboard-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1f2937;
    margin: 0;
  }
  
  .upgrade-button {
    background-color: #8b5cf6;
    color: white;
    border: none;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .upgrade-button:hover {
    background-color: #7c3aed;
  }
  
  /* Section Headers */
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .section-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    margin: 0;
  }
  
  .service-count {
    font-size: 0.875rem;
    color: #6b7280;
    margin: 0;
  }
  
  /* Services Grid */
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .service-card {
    background-color: white;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .service-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .service-image-container {
    width: 100%;
    height: 160px;
    overflow: hidden;
  }
  
  .service-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
  }
  
  .service-card:hover .service-image {
    transform: scale(1.05);
  }
  
  .service-details {
    padding: 1rem;
  }
  
  .service-name {
    font-size: 1.125rem;
    font-weight: 600;
    color: #111827;
    margin: 0 0 0.75rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .service-stats {
    display: flex;
    margin-bottom: 1rem;
  }
  
  .stat {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .stat-icon {
    margin-right: 0.375rem;
  }
  
  .provider-service-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
  
  .view-button,
  .analytics-button {
    padding: 0.5rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .view-button {
    background-color: #e5e7eb;
    color: #4b5563;
  }
  
  .view-button:hover {
    background-color: #d1d5db;
  }
  
  .analytics-button {
    background-color: #10b981;
    color: white;
  }
  
  .analytics-button:hover {
    background-color: #059669;
  }
  
  .view-button svg,
  .analytics-button svg {
    margin-right: 0.375rem;
  }
  
  /* Loading State */
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    color: #6b7280;
  }
  
  /* Empty State */
  .empty-state {
    text-align: center;
    padding: 3rem 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
  
  .empty-state p {
    color: #6b7280;
    margin-bottom: 1.5rem;
  }
  
  .add-service-button,
  .back-to-home-button {
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 0.375rem;
    padding: 0.75rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .add-service-button:hover,
  .back-to-home-button:hover {
    background-color: #2563eb;
  }

  /* Discount Toggle */
.discount-toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #e5e7eb;
}

.discount-toggle-label {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #4b5563;
}

.discount-icon {
  margin-right: 0.5rem;
  color: #8b5cf6;
}

/* Toggle Switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e7eb;
  transition: .3s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .3s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #8b5cf6;
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px #8b5cf6;
}

input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

/* Disabled state */
input:disabled + .toggle-slider {
  background-color: #d1d5db;
  cursor: not-allowed;
}

input:disabled + .toggle-slider:before {
  background-color: #f3f4f6;
}
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: 1fr;
    }
    
    .service-actions {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 640px) {
    .header-container {
      flex-direction: column;
      height: auto;
      padding: 1rem 0;
      gap: 0.75rem;
    }
    
    .header-left {
      width: 100%;
      justify-content: flex-start;
    }
    
    .upgrade-button {
      width: 100%;
    }
    
    .section-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
  }