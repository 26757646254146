:root {
  --primary-color: #2563eb;
  --secondary-color: #10b981;
  --background-light: #f4f4f6;
  --text-dark: #1f2937;
  --text-gray: #6b7280;
  --border-color: #e5e7eb;
  --white: #ffffff;
  --transition-speed: 0.3s;
}

.service-provider-form {
  max-width: 700px;
  margin: 2.5rem auto;
  padding: 2.5rem;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.service-provider-form h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 2rem;
  text-align: center;
  letter-spacing: -0.025em;
}

.service-provider-form h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-dark);
  margin-bottom: 1.5rem;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 0.5rem;
}

.service-provider-form label {
  display: block;
  margin-bottom: 0.75rem;
  color: var(--text-gray);
  font-weight: 500;
}

.service-provider-form input,
.service-provider-form select,
.service-provider-form textarea {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  font-size: 1rem;
  background-color: var(--background-light);
  transition: 
    border-color var(--transition-speed) ease,
    box-shadow var(--transition-speed) ease;
}

.service-provider-form input:focus,
.service-provider-form select:focus,
.service-provider-form textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.service-provider-form textarea {
  resize: vertical;
  min-height: 120px;
}

.service-provider-form button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: 
    background-color var(--transition-speed) ease,
    transform var(--transition-speed) ease;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.service-provider-form .submit-button,
.service-provider-form .next-button {
  background-color: var(--primary-color);
  color: var(--white);
}

.service-provider-form .prev-button {
  background-color: var(--background-light);
  color: var(--text-gray);
}

.service-provider-form button:hover {
  transform: translateY(-2px);
}

.service-provider-form .submit-button:hover {
  background-color: #1e40af;
}

.service-provider-form .prev-button:hover {
  background-color: #e5e7eb;
}

.service-provider-form .navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  gap: 1rem;
}

.service-provider-form .navigation-buttons button {
  flex: 1;
}

.service-provider-form .progress-bar {
  background-color: var(--border-color);
  border-radius: 1rem;
  height: 0.5rem;
  margin: 2rem 0;
  overflow: hidden;
}

.service-provider-form .progress-bar-fill {
  background-color: var(--primary-color);
  height: 100%;
  transition: width var(--transition-speed) ease;
}

.service-provider-form .progress-bar-text {
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  color: var(--text-gray);
  font-size: 0.875rem;
}

.order-method-section {
  background-color: var(--background-light);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.add-order-method-button,
.add-location-button,
.add-service-button {
  background-color: var(--secondary-color);
  color: var(--white);
  display: block;
  width: 100%;
  margin-top: 1rem;
}

.remove-order-method,
.remove-location-button,
.remove-service-button {
  background-color: #ef4444;
  color: var(--white);
  margin-top: 0.5rem;
}

.error-message {
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  display: block;
}

@media (max-width: 600px) {
  .service-provider-form {
    margin: 1rem;
    padding: 1.5rem;
  }

  .service-provider-form .navigation-buttons {
    flex-direction: column;
  }

  .service-provider-form .navigation-buttons button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}