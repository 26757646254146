:root {
  --primary-color: #2563eb;
  --secondary-color: #10b981;
  --accent-color: #6366f1;
  --discount-color: #ef4444;
  --background-light: #f4f4f6;
  --text-dark: #1f2937;
  --text-gray: #6b7280;
  --white: #ffffff;
  --transition-speed: 0.3s;
}

.pricing-container {
  max-width: 1100px;
  margin: 0.5rem auto;
  padding: 3rem;
  background-color: var(--white);
  border-radius: 1.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.08);
  text-align: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.pricing-container h1 {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1rem;
  color: var(--text-dark);
  letter-spacing: -0.05em;
}

.pricing-container > p {
  font-size: 1.125rem;
  color: var(--text-gray);
  margin-bottom: 1.5rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Early adopter banner */
.early-adopter-banner {
  background: linear-gradient(90deg, var(--primary-color), var(--discount-color));
  color: var(--white);
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { opacity: 0.8; }
  50% { opacity: 1; }
  100% { opacity: 0.8; }
}

.pricing-plans {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  align-items: stretch;
}

.plan {
  flex: 1;
  min-width: 280px;
  max-width: 350px;
  background-color: var(--background-light);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: 
    transform var(--transition-speed) ease,
    box-shadow var(--transition-speed) ease;
  border: 2px solid transparent;
  justify-content: space-between;
}

.plan::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
}

.plan:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  border-color: var(--primary-color);
}

.plan h2 {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 1.5rem;
}

.plan p {
  color: var(--text-gray);
  margin-bottom: 0.75rem;
  font-size: 1rem;
}

.plan strong {
  color: var(--accent-color);
}

/* Price Display Styles */
.price-display {
  margin: 1.5rem 0 0.75rem;
  position: relative;
}

.original-price {
  position: relative;
  display: inline-block;
  color: var(--text-gray);
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.original-price strong {
  color: var(--text-gray);
  text-decoration: line-through;
  text-decoration-color: var(--discount-color);
  text-decoration-thickness: 2px;
}

.discounted-price {
  font-size: 1.25rem;
  margin: 0.25rem 0;
}

.discounted-price strong {
  color: var(--discount-color);
  font-weight: 800;
}

.savings-text {
  color: var(--discount-color);
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.trial-text {
  background-color: var(--secondary-color);
  color: var(--white);
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  display: inline-block;
  margin-bottom: 1.5rem;
}

.badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: var(--accent-color);
  color: var(--white);
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  transform: rotate(5deg);
}

.plan button {
  margin-top: auto;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--white);
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 
    background-color var(--transition-speed) ease,
    transform var(--transition-speed) ease;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.plan.monthly {
  background: linear-gradient(145deg, #e6f2ff, var(--background-light));
}

.plan.quarterly {
  background: linear-gradient(145deg, #e8f5e9, var(--background-light));
}

.plan.annual {
  background: linear-gradient(145deg, #fff3e0, var(--background-light));
}

.plan.monthly button {
  background-color: #2196f3;
}

.plan.quarterly button {
  background-color: #4caf50;
}

.plan.annual button {
  background-color: #ff5722;
}

.plan button:hover {
  transform: translateY(-3px);
  opacity: 0.9;
}

.pricing-info {
  margin-top: 3rem;
  padding: 2rem;
  background-color: var(--background-light);
  border-radius: 1rem;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: 2px dashed var(--discount-color);
}

.pricing-info::before {
  content: '🚀';
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 5rem;
  opacity: 0.1;
}

.pricing-info h3 {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.pricing-info p {
  font-size: 1.125rem;
  color: var(--text-gray);
  max-width: 700px;
  margin: 0 auto 1rem;
}

.pricing-info p strong {
  color: var(--discount-color);
}

.countdown-timer {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--discount-color);
  margin-top: 1.5rem;
}

.all-plans-include {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-dark);
  margin: 1.5rem 0 0.5rem;
  text-align: center;
}

.pricing-features-section {
  max-width: 700px;
  margin: 0 auto 2.5rem;
  padding: 1.5rem 2rem;
  background-color: var(--background-light);
  border-radius: 1rem;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.pricing-feature-item {
  margin: 0.75rem 0;
  color: var(--text-dark);
  font-size: 1.05rem;
  position: relative;
  padding-left: 0.5rem;
}

.plan-subtitle {
  font-size: 0.95rem;
  color: var(--text-gray);
  margin-top: -0.75rem;
  margin-bottom: 1.5rem;
}

.per-month-price {
  font-size: 0.9rem;
  color: var(--accent-color);
  margin-top: 0.25rem;
  font-weight: 500;
}

/* Enhanced Media Queries for Better Responsiveness */

/* Large Desktop (1200px and above) */
@media (min-width: 1200px) {
  .pricing-container {
    padding: 4rem;
  }
  
  .plan {
    min-width: 300px;
    padding: 2.5rem;
  }
}

/* Standard Desktop (992px to 1199px) */
@media (max-width: 1199px) and (min-width: 992px) {
  .pricing-container h1 {
    font-size: 2.75rem;
  }
}

/* Tablet (768px to 991px) */
@media (max-width: 991px) and (min-width: 768px) {
  .pricing-container {
    padding: 2.5rem;
    margin: 1rem auto;
  }
  
  .pricing-container h1 {
    font-size: 2.5rem;
  }
  
  .plan {
    min-width: 220px;
    padding: 1.75rem;
  }
}

/* Mobile Landscape (576px to 767px) */
@media (max-width: 767px) and (min-width: 576px) {
  .pricing-container {
    padding: 2rem 1.5rem;
    margin: 1rem;
    border-radius: 1rem;
  }
  
  .pricing-container h1 {
    font-size: 2.25rem;
  }
  
  .pricing-plans {
    flex-direction: column;
    align-items: center;
  }
  
  .plan {
    width: 100%;
    max-width: 500px;
    margin-bottom: 1.5rem;
  }
  
  .pricing-features-section {
    padding: 1.25rem 1.5rem;
  }
  
  .early-adopter-banner {
    padding: 0.6rem;
    font-size: 0.95rem;
  }
}

/* Mobile Portrait (575px and below) */
@media (max-width: 575px) {
  .pricing-container {
    padding: 1.5rem 1rem;
    margin: 0.5rem;
    border-radius: 0.75rem;
  }
  
  .pricing-container h1 {
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
  }
  
  .pricing-container > p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .pricing-plans {
    flex-direction: column;
    gap: 1.25rem;
  }
  
  .plan {
    width: 90%;
    max-width: none;
    padding: 1.5rem 1rem;
  }
  
  .plan h2 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }
  
  .plan-subtitle {
    margin-bottom: 1rem;
  }
  
  .badge {
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
    top: 0.75rem;
    right: 0.75rem;
  }
  
  .pricing-features-section {
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .pricing-feature-item {
    font-size: 0.95rem;
    margin: 0.5rem 0;
  }
  
  .early-adopter-banner {
    padding: 0.5rem;
    font-size: 0.85rem;
    margin-bottom: 1.5rem;
  }
  
  .pricing-info {
    margin-top: 1.5rem;
    padding: 1.25rem;
  }
  
  .pricing-info h3 {
    font-size: 1.4rem;
  }
  
  .pricing-info p {
    font-size: 0.95rem;
  }
  
  .countdown-timer {
    font-size: 1rem;
  }
  
  .all-plans-include {
    font-size: 1.1rem;
  }
}

/* Very Small Devices (320px and below) */
@media (max-width: 320px) {
  .pricing-container h1 {
    font-size: 1.5rem;
  }
  
  .plan {
    padding: 1.25rem 0.75rem;
  }
  
  .plan button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
  
  .pricing-feature-item {
    font-size: 0.85rem;
  }
}