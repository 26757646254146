body, html {
  overflow-x: hidden;

}


.continent-page {
  font-family: JETSansDigital, sans-serif !important;
  background-color: #f9f9f9;
  padding-top: 130px; /* Adds padding to the top of the body to account for the fixed header */

}

.carousel-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 200px;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

.carousel-content h2 {
  margin: 0;
  font-size: 1.5rem;
}

.carousel-content p {
  margin: 10px 0;
}

.carousel-content button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel-content button:hover {
  background-color: #0056b3;
}

.welcome-heading {
  color: rgb(71, 71, 71);
  padding-top: 0;
  border-radius: 8px;
  text-align: center;
  font-size: 2.5rem;
  margin: 1rem 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

@keyframes wave-animation {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

.wave {
  display: inline-block;
  animation: wave-animation 2s infinite;
  transform-origin: 70% 70%;
}

main {
  padding: 0;
  margin-left: 6rem;
  margin-right: 6rem;
}

.continent-info {
  margin: 2rem 0;
}

.intro {
  color: rgb(71, 71, 71);
  text-align: center;
}

.services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.service-item {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-item img {
  width: 210px;
  height: auto;
  border-radius: 8px;
}

.service-info {
  flex: 1;
  text-align: left;
}

.service-info h3 {
  margin: 0 0 0.5rem 0;
}

.service-info p {
  margin: 0 0 1rem 0;
}

button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.provider-call {
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Skeleton Loading Styles */
.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.skeleton-text {
  width: 100%;
  height: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.skeleton-image {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.skeleton-button {
  width: 100px;
  height: 40px;
  border-radius: 4px;
}

.skeleton-service-item {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skeleton-service-item img {
  width: 210px;
  height: auto;
  border-radius: 8px;
}


/* Responsive Design */

@media (max-width: 480px) {
  .welcome-heading {
    font-size: 2rem;
  }

  .carousel-item {
    height: 170px;
  }

  main {
    padding: 0;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .continent-info {
    font-size: 12px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .services {
    grid-template-columns: 1fr;
  }

  .service-item {
    flex-direction: column;
    text-align: center;
  }

  .service-item img {
    width: 200px;
  }

  .service-info {
    text-align: center;
  }

  .service-info h3 {
    font-size: 1.2rem;
    
  }

  .service-info p {
    font-size: 1rem;
  }

  .provider-call {
    font-size: 0.9rem;
  }
}

@media (max-width: 1200px) {
  .welcome-heading {
    font-size: 2rem;
  }

  .carousel-item {
    height: 170px;
  }

  main {
    padding: 0;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .continent-info {
    font-size: 12px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .services {
    grid-template-columns: 1fr;
  }

  .service-item {
    flex-direction: column;
    text-align: center;
  }

  .service-item img {
    width: 200px;
  }

  .service-info {
    text-align: center;
  }

  .service-info h3 {
    font-size: 1.2rem;
    
  }

  .service-info p {
    font-size: 1rem;
  }

  .provider-call {
    font-size: 0.9rem;
  }
}
