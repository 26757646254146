.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #f8f9fa;
    height: 70px;
    position: fixed; /* Fixes the header at the top */
    top: 0; /* Aligns it to the top of the viewport */
    left: 0; /* Aligns it to the left of the viewport */
    width: 100%; /* Ensures the header spans the full width of the viewport */
    z-index: 1000; /* Ensures the header stays on top of other content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for better visibility */
    background: linear-gradient(to bottom, #aae5f9, #fcfefe);
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo img {
    width: 50px;
    height: auto;
    margin-right: 0.5rem;
  }
  
  .logo span {
    font-size: 1.2rem;
    color: #555;
    font-weight: bold;
  }
  
  .search-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    flex-grow: 1;
    position: relative;
    padding-right: 5px;
    
  }
  
  .search-input-wrapper {
    display: flex;
    align-items: center;
    border-radius: 250px;
    background-color: #f4f4f4;
    padding: 0.5rem 1rem;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .search-icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
    color: #555;
  }
  
  .search-input {
    border: none;
    outline: none;
    font-size: 16px;
    width: 420px;
    height: 20px;
    background-color: #f4f4f4;
  }
  
  .search-input::placeholder {
    color: #808080;
    font-weight: 540;
    font-size: 15px;
    padding-left: 8px;

  }
  
  .clear-icon {
    margin-left: 0.5rem;
    font-size: 1.2rem;
    color: #555;
    cursor: pointer;
  }
  

  .search-results {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-left: 6px;
    margin-top: 0px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    width: 500px;
    z-index: -2;
    padding-top: 50px;
  }

  .search-result-item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    
  }

  .search-result-item h4{
    margin-block-start: 0;
    margin-block-end: 4px;
  }

  .search-result-item p{
    margin-block-start: 0;
  }

  .search-result-item:hover {
    background-color: #f0f0f0;
  }

  .search-result-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .no-results {
    padding: 8px;
    text-align: center;
    color: #888;
  }

  
  .header-actions {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 60px;
  }
  
  .header-action-item.address {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #555;
    padding-right: 10px;
  }
  
  .header-button {
    background: none;
    /* border-radius: 50px; */
    border: none;
    color: #555;
    font-size: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 2px;
    padding-right: 2px;
  }

  .header-action-item.address:hover {
    cursor: pointer;
  }
  
  .header-button:hover {
    background-color: #19b0e3;
  }
  
  
  .header-icon {
    font-size: 1.3rem;
    width: 20px; /* Icon size */
    height: 20px;
    margin-right: 8px; /* Space between icon and text */
  }

  .header-profile-icon {
    width: 25px; /* Icon size */
    height: 25px;
    margin-right: 0px; /* Space between icon and text */
    transition: all 0.3s ease;
  }

  .header-profile-icon:hover {
    width: 30px; /* Icon size */
    height: 30px;
  }
  
  /* Toggle Switch Styles */
  .toggle-switch {
    display: flex;
    align-items: center;
    background-color: #b8b8b8;
    border-radius: 25px;
    width: 50px;
    height: 20px;
    cursor: pointer;
    position: relative;
  }
  
  .toggle-option {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
    border-radius: 25px;
    width: 50%;
    height: 100%;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    top: 0;
    transition: transform 0.3s ease;
  }
  
  .toggle-option.light {
    left: 0;
    transform: translateX(0%);
  }
  
  .toggle-option.dark {
    right: 0;
    transform: translateX(-100%);
  }
  
  .is-dark-mode .toggle-switch .toggle-option {
    background-color: #efefef;
    color: #fff;
  }
  
  .dark-mode .header {
    background-color: #1f1f1f;
  }
  
  .dark-mode .search-input-wrapper {
    background-color: #333;
  }
  
  .dark-mode .search-input::placeholder {
    color: #aaa;
  }
  
  .dark-mode .header-button {
    color: #ddd;
  }
  
  .dark-mode .toggle-switch {
    background-color: #333;
  }

  /*DARK MODE STYLES*/

.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .header {
  background-color: #1f1f1f;
}

.dark-mode .carousel-item {
  background-color: #1f1f1f;
}

.dark-mode .continent-page {
  background-color:  #1f1f1f;
}

.dark-mode .welcome-heading {
  color: rgb(245, 245, 245);
}

.dark-mode .header {
  background-color: #1f1f1f;
}

.dark-mode .search-input-wrapper {
  background-color: #333;
}

.dark-mode .search-input::placeholder {
  color: #aaa;
}

.dark-mode .header-button {
  color: #ddd;
}

.dark-mode .toggle-switch {
  background-color: #333;
}


/* Burger Menu Styles */
.burger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  cursor: pointer;
  z-index: 10;
  padding-right: 15px;
}

.burger-bar {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

.burger-menu.open .burger-bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-menu.open .burger-bar:nth-child(2) {
  opacity: 0;
}

.burger-menu.open .burger-bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Header Actions for Mobile */
.header-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-actions.open {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  background-color: rgba(255, 255, 255, 0.98);
  flex-direction: column;
  padding: 2rem;
  z-index: 5;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.2);
}

.header-actions.open .header-action-item,
.header-actions.open .header-button {
  text-align: left;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.header-login-button {
  background-color: #0fbaf4;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}


/* Responsive Adjustments */





@media (max-width: 480px) {
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 0;
  }

  .logo{
    padding-left: 15px;
  }

  .logo span{
    font-size: 25px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    padding-top: 1.3rem;
    height: auto;
    width: 100%;
  }
  
  .search-bar {
    margin-left: 0;
    width: 100%;
    margin-bottom: 1rem;
    padding-top: 5px;
    padding-left: 15px;
  }
  
  .search-input-wrapper {
    width: 300px;
    height: 28px;
  }

  .search-input {
    width: 100%;
  }
  
  .search-icon {
    margin-right: 0.5rem;
    font-size: 0.8rem;
    color: #555;
  }
  
  .search-input::placeholder {
    font-size: 16px;
    font-weight: 400;
  }
  
  .clear-icon {
    font-size: 1rem;
  }

  .header-actions {
    display: none;
  }

  .header-actions.open {
    display: flex;
  }

  .burger-menu {
    display: flex;
    
  }

  .header-action-item.address{
    justify-content: center;
  }

  .header-button{
    justify-content: center;
    background: none;
  }

  .toggle-switch {
    justify-content: center;
  }

  
  .search-results {
    border-radius: 15px;
    width:320px;
  }


  .header-icon {
    width: 30px; /* Icon size */
    height: 30px;
  }

  .header-profile-icon {
    width: 40px; /* Icon size */
    height:40px;
    margin-right: 8px; /* Space between icon and text */
  }
}

@media (max-width: 1200px) {

  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 0;
  }

  .logo{
    padding-left: 15px;
  }

  .logo span{
    font-size: 25px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    padding-top: 1.3rem;
    height: auto;
    width: 100%;
  }
  
  .search-bar {
    margin-left: 0;
    width: 100%;
    margin-bottom: 1rem;
    padding-top: 5px;
    padding-left: 15px;
  }
  
  .search-input-wrapper {
    width: 70vw;
    height: 28px;
  }

  .search-input {
    width: 100%;
  }
  
  .search-icon {
    margin-right: 0.5rem;
    font-size: 0.8rem;
    color: #555;
  }
  
  .search-input::placeholder {
    font-size: 16px;
    font-weight: 400;
  }
  
  .clear-icon {
    font-size: 1rem;
  }

  .header-actions {
    display: none;
  }

  .header-actions.open {
    display: flex;
  }

  .burger-menu {
    display: flex;
    
  }

  .header-action-item.address{
    justify-content: center;
  }

  .header-button{
    justify-content: center;
    background: none;
  }

  .toggle-switch {
    justify-content: center;
  }

  
  .search-results {
    border-radius: 15px;
    width:320px;
  }


  .header-icon {
    width: 30px; /* Icon size */
    height: 30px;
  }

  .header-profile-icon {
    width: 40px; /* Icon size */
    height:40px;
    margin-right: 8px; /* Space between icon and text */
  }
}
  
  