/* Google-style rating container */
.rating-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
    margin-bottom: 10px;
    font-size: 0.9rem;
  }
  
  .rating-score {
    font-weight: bold;
    color: #333;
  }
  
  .rating-stars {
    display: flex;
    color: #e7711b; /* Google's gold star color */
  }
  
  .star-filled {
    color: #e7711b;
  }
  
  .star-half {
    position: relative;
    color: #e7711b;
  }
  
  .star-empty {
    color: #e1e1e1;
  }
  
  .rating-count {
    color: #70757a; /* Google's gray text color */
  }
  
  .price-range {
    color: #70757a;
    margin-left: 5px;
  }
  
  .price-range::before {
    content: "·";
    margin-right: 5px;
  }
  
  .service-category {
    color: #70757a;
    margin-left: 5px;
  }
  
  .service-category::before {
    content: "·";
    margin-right: 5px;
  }