/* Container for the page content */
.enter-location-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background: linear-gradient(to top, #2eb5e1, #eaf4fc);
    padding: 20px; /* Padding for responsiveness */
  }
  
  /* Form container styling */
  .form-container {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px; /* Maximum width for the form */
    text-align: center; /* Center the form content */
  }
  
  /* Label styling */
  .form-container label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Select dropdown styling */
  .form-container select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
  }
  
  /* Button styling */
  .form-container button {
    width: 100%; /* Full width button */
    font-size: 1.2rem;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Button hover effect */
  .form-container button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  /* Button active state */
  .form-container button:active {
    background-color: #003d7a; 
  }

  