/* SignUp.css */

/* Center the form container on the page */
.signup-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f7f8fa;
  }
  
  /* Styling for the form container */
  .signup-form-container {
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    max-width: 90%;
    text-align: center; /* Center align the text inside the form container */
  }
  
  /* Form title styling */
  .signup-form-container h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Styling for the input fields */
  .signup-form-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Styling for the submit button */
  .signup-form-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Hover effect for the submit button */
  .signup-form-container button:hover {
    background-color: #0056b3;
  }
  
  /* Styling for the login link */
  .signup-form-container p {
    margin-top: 10px;
    text-align: center; /* Ensure text is centered */
  }
  
  .signup-form-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  /* Hover effect for the login link */
  .signup-form-container a:hover {
    text-decoration: underline;
  }
  