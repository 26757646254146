/* AddressModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content label {
    display: block;
    margin-bottom: 10px;
  }
  
  .modal-content input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  
  .modal-actions button {
    padding: 10px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-actions button:hover {
    opacity: 0.8;
  }
  
  .modal-actions button:first-child {
    background-color: #ccc;
  }
  
  .modal-actions button:last-child {
    background-color: #007bff;
    color: white;
  }
  