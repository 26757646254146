.user-profile {
  max-width: 600px;
  margin: 30px auto;
  padding: 40px;
  background-color: #f4f6f9;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Inter', 'Arial', sans-serif;
  position: relative;
  transition: all 0.3s ease;
}

.back-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #4a4a4a;
  transition: all 0.3s ease;
}

.back-icon:hover {
  color: #3a7bd5;
  transform: translateX(-5px);
}

.user-profile h1 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
  background: linear-gradient(to right, #3a7bd5, #3a6073);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.user-profile h2 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #34495e;
  margin-bottom: 15px;
  text-align: center;
  width: 100%;
}

.user-profile p {
  font-size: 1rem;
  color: #7f8c8d;
  text-align: center;
  margin-bottom: 20px;
}

.profile-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
  width: 100%;
}

.user-profile button {
  padding: 12px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.user-profile .go-back-button,
.user-profile .log-out-button {
  background: linear-gradient(to right, #3a7bd5, #3a6073);
  color: white;
}

.user-profile .go-back-button:hover,
.user-profile .log-out-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(58, 123, 213, 0.3);
}

.user-profile .delete-button {
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  color: white;
}

.user-profile .delete-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(255, 65, 108, 0.3);
}

.user-profile input {
  width: 100%;
  padding: 12px 15px;
  margin: 10px 0;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-size: 1rem;
  background-color: white;
  transition: all 0.3s ease;
}

.user-profile input:focus {
  outline: none;
  border-color: #3a7bd5;
  box-shadow: 0 0 0 2px rgba(58, 123, 213, 0.2);
}

.update-password-button {
  background: linear-gradient(to right, #11998e, #38ef7d);
  color: white;
  margin-top: 15px;
}

.update-password-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(17, 153, 142, 0.3);
}

.favorites-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 20px;
  margin-top: 25px;
  width: 100%;
}

.favorite-service {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.favorite-service:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.favorite-service-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 8px;
  margin-bottom: 15px;
}

.favorite-service h3 {
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #2c3e50;
  font-weight: 600;
}

.favorite-service button {
  margin-top: auto;
  background: linear-gradient(to right, #3a7bd5, #3a6073);
  color: white;
  padding: 10px 15px;
  font-size: 0.9rem;
}

.favorite-service button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(58, 123, 213, 0.3);
}

/* Responsive Design */
@media (max-width: 480px) {
  .user-profile {
    padding: 25px;
    margin: 15px;
  }

  .back-icon {
    top: 15px;
    left: 15px;
  }

  .profile-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .favorites-list {
    grid-template-columns: 1fr;
  }
}