/* NoServices.css */

.no-services-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    text-align: center;
    padding-top: 120px;
}
  
.no-services-content {
    background: #fff;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
}
  
.no-services-content h1 {
    font-size: 2.5rem;
    margin-bottom: 45px;
    color: #333;
}
  
.no-services-content p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    color: #666;
}
  
.buttons {
    margin-top: 20px;
}
  
button {
    padding: 10px 20px;
    margin: 10px 0;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
button:hover {
    background-color: #0056b3;
}
  
.provider-call {
    margin-top: 30px;
    margin-bottom: 30px;
}
  
.provider-call h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}
  
.provider-call button {
    padding: 10px 20px;
    background-color: #28a745;
}
  
.provider-call button:hover {
    background-color: #218838;
}
  
.navigation-buttons {
    margin-top: 2rem;
}
  
.navigation-buttons button {
    margin-right: 1rem;
}

/* Responsive Design */

@media (max-width: 480px) {
    .no-services-content h1 {
        font-size: 2rem;
    }
}
