:root {
  --primary-color: #2563eb;
  --secondary-color: #10b981;
  --accent-color: #6366f1;
  --success-color: #10b981;
  --warning-color: #f59e0b;
  --background-light: #f4f4f6;
  --text-dark: #1f2937;
  --text-gray: #6b7280;
  --white: #ffffff;
  --transition-speed: 0.3s;
}

.submission-success {
  max-width: 650px;
  margin: 4rem auto;
  text-align: center;
  padding: 2.5rem;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.submission-success::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
}

/* Success Icon */
.success-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: rgba(16, 185, 129, 0.1);
  border-radius: 50%;
  color: var(--success-color);
  font-size: 2.5rem;
  font-weight: bold;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(16, 185, 129, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(16, 185, 129, 0);
  }
}

/* Headings and text */
.submission-success h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 0.75rem;
  letter-spacing: -0.025em;
}

.confirmation-message {
  font-size: 1.125rem;
  color: var(--text-gray);
  margin-bottom: 2rem;
}

.next-steps-container {
  text-align: left;
  margin: 2rem 0;
  padding: 1.5rem;
  background-color: var(--background-light);
  border-radius: 0.75rem;
}

.next-steps-container h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-dark);
  margin-bottom: 1.25rem;
  text-align: center;
}

/* Step items */
.step-item {
  display: flex;
  margin-bottom: 1.25rem;
  align-items: flex-start;
}

.step-item:last-child {
  margin-bottom: 0;
}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: var(--primary-color);
  color: var(--white);
  border-radius: 50%;
  font-weight: 600;
  flex-shrink: 0;
  margin-right: 1rem;
  margin-top: 0.25rem;
}

.step-content {
  flex: 1;
}

.step-content h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-dark);
  margin-bottom: 0.25rem;
}

.step-content p {
  font-size: 0.95rem;
  color: var(--text-gray);
  line-height: 1.5;
  margin: 0;
}

/* Important note */
.important-note {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  background-color: rgba(245, 158, 11, 0.1);
  border-radius: 0.5rem;
  margin: 1.5rem 0;
  text-align: left;
}

.note-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: var(--warning-color);
  color: var(--white);
  border-radius: 50%;
  font-weight: 600;
  margin-right: 1rem;
  flex-shrink: 0;
}

.important-note p {
  font-size: 0.95rem;
  color: var(--text-dark);
  margin: 0;
  line-height: 1.5;
}

.email-notification {
  font-size: 0.95rem;
  color: var(--text-gray);
  margin: 1.5rem 0;
  padding: 0 1rem;
}

/* Action buttons */
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1.5rem;
}

.primary-button, .secondary-button {
  font-size: 1rem;
  font-weight: 600;
  padding: 0.9rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 
    background-color var(--transition-speed) ease,
    transform var(--transition-speed) ease;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.primary-button {
  background-color: var(--primary-color);
  color: var(--white);
}

.secondary-button {
  background-color: var(--background-light);
  color: var(--text-dark);
  border: 1px solid var(--text-gray);
}

.primary-button:hover, .secondary-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.primary-button:hover {
  background-color: #1d4ed8;
}

.secondary-button:hover {
  background-color: #e5e7eb;
}

@media (min-width: 640px) {
  .action-buttons {
    flex-direction: row;
    justify-content: center;
  }
  
  .primary-button, .secondary-button {
    min-width: 200px;
  }
}

@media (max-width: 639px) {
  .submission-success {
    margin: 2rem 1rem;
    padding: 2rem 1.5rem;
  }
  
  .next-steps-container {
    padding: 1.25rem;
  }
  
  .step-item {
    margin-bottom: 1.5rem;
  }
}